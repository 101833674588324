import React from 'react';
import "../styles/Services.css"
import { IoColorWandOutline } from "react-icons/io5"
import { BiCodeAlt } from "react-icons/bi"
import { ImMobile } from "react-icons/im"
import { motion } from "framer-motion";

const Services = () => {

    const fade = {
        opacity: 1,
        transition: {
            duration: 1.4
        }
    }

    return (
        <>
            <div className="services" id='services'>
                <div className="container">
                    <motion.div whileInView={fade} viewport={{ once: true }} initial={{ opacity: 0 }} className="heading">
                        <p className="heading-sub-text">What can we do</p>
                        <p className='heading-text'>Services</p>
                    </motion.div>
                    <motion.div className="services-box" whileInView={fade} initial={{ opacity: 0 }}>
                        <div className="services-card">
                            <BiCodeAlt className='services-icon' />
                            <p className='services-title'>Design</p>
                            <p className='services-desc'>We provide our customers with the best graphic designers to manage their needs and meet their business ends.</p>
                        </div>
                        <div className="services-card">
                            <ImMobile className='services-icon' />
                            <p className='services-title'>Marketing</p>
                            <p className='services-desc'>Great design wrok is useless without a good marketing strategy that can utilize it, here in pixel we provide our clients with the best marketing experts to help them reach their optimal client.</p>
                        </div>
                        <div className="services-card">
                            <IoColorWandOutline className='services-icon' />
                            <p className='services-title'>Printing</p>
                            <p className='services-desc'>Printed advertisement may seem to have fallen behind but our market experts still emphasize its importance that's why, in association with the top print houses in the country, we provide our clients with the best printing services in the country.</p>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    )
};

export default Services;
