import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '../styles/Partners.css';
import { motion } from "framer-motion";

export default function Partners() {

  const fade = {
    opacity: 1,
    transition: {
        duration: 1.4
    }
}

  const images = [
    {
      original: "/partners/Logo1.png",
    },
    {
      original: "/partners/Logo2.png",
    },
    {
      original: "/partners/Logo3.png",
    },
    {
      original: "/partners/Logo4.png",
    },
    {
      original: "/partners/Logo5.png",
    },
    {
      original: "/partners/Logo6.png",
    },
    {
      original: "/partners/Logo7.png",
    }
  ];

  return (
    <div className="services" id='services'>
      <div className="container">
        <motion.div whileInView={fade} viewport={{ once: true }} initial={{ opacity: 0 }} className="heading">
          <p className="heading-sub-text">Who have we worked with</p>
          <p className='heading-text'>Our Partners</p>
        </motion.div>
        <motion.div className='partners'>
          <Splide options={{
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 3,
            perMove: 1,
            autoplay: true,
            gap: '1rem',
            controls: false,
          }} aria-label="Partners section">

            {images.map((image) => (
              <SplideSlide key={image.original}>
                <img className='partner_image' src={image.original} alt={image.original} />
              </SplideSlide>
            ))}
          </Splide>
        </motion.div>
      </div>
    </div>
  )
}
