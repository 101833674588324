export const TeamsData = [
	{
		title: "IBRAHIM ABDULLAH",
		desc: "CEO and Founder",
		site: "//ibrahim.pixel.com.ly/",
		img: "images/team1.png",
	},
	{
		title: "ASEM BADER",
		desc: "Copywriter and Co-founder",
		site: "//www.facebook.com/asem.kimsesiz.7",
		img: "images/team2.png",
	},
	{
		title: "AHMED ALANSARY",
		desc: "Public Relations, Spokesman",
		site: "//www.facebook.com/a7med.alansary",
		img: "images/team3.png",
	},
	{
		title: "ALSADIQ ABDORABAH",
		desc: "Interior Designer and Technitian",
		site: "//www.facebook.com/alsadiq.ly",
		img: "images/team4.png",
	},
];
