import React from 'react';
import "../styles/About.css"
import { motion } from "framer-motion";
import ProfileImg from '../images/profile.jpg'

const About = () => {

  const horizontal ={
    x:0,
    opacity: 1,
    transition:{type: 'spring', duration: 2,bounce: 0.3}
  }

  return (
      <>
          <div  className="about" id='about'>
              <div className="container">
                  <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} viewport={{ once: true }} className="heading">
                    <p className="heading-sub-text">Who are We</p>
                    <p className='heading-text'>About Us</p>
                  </motion.div>
                  <div className="split-about">
                    <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} className="about-content">
                        <p>Pixel is an advertisement agency based in Awbari, Libya.</p>
                        <br />
                        <p>We provides you with the opportunity to manage and handle all the promotional and advertisement related services of your company or local business, our services and offers vary to include all features for all budgets no matter how limited they were.</p>
                        <br />
                        <p>We also provide you with the chance to work with Libya’s top advertisement pioneers and provide you with advice that would help your work thrive.</p>
                    </motion.div>
                    <motion.div initial={{x: '50', opacity: 0}} whileInView={horizontal}  className='about-img'>
                        <img src={ProfileImg} alt="Profile" />
                    </motion.div>
                  </div>
              </div>
          </div>
      </>
  )
};

export default About;
