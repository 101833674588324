import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram} from "react-icons/fa"
import {BiEnvelope} from "react-icons/bi"
import {BsWhatsapp} from "react-icons/bs"

const NavLinks = ({handleNav}) => {
  return (
    <ul className='nav-links'>
        <li onClick={handleNav} ><Link  to="//www.facebook.com/LibyaPixelinc" target='_blank' className='nav-link'><FaFacebook /></Link></li>
        <li onClick={handleNav} ><Link  to="//www.instagram.com/pixel.libya/" target='_blank' className='nav-link'><FaInstagram /></Link></li>
        <li onClick={handleNav} ><Link  to="//wa.me/218918244426" target='_blank' className='nav-link'><BsWhatsapp /></Link></li>
        <li onClick={handleNav} ><Link  to="//mailto:contact@pixel.com.ly" target='_blank' className='nav-link'><BiEnvelope /></Link></li>
  </ul>
  )
};

export default NavLinks;
