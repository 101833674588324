import ImageGallery from "react-image-gallery";
import { motion } from "framer-motion";
import "../styles/Gallery.css";

const images = [
	{
		original: "gallery/works (1).jpg",
		thumbnail: "gallery/works (1).jpg",
	},
	{
		original: "gallery/works (2).jpg",
		thumbnail: "gallery/works (2).jpg",
	},
	{
		original: "gallery/works (3).jpg",
		thumbnail: "gallery/works (3).jpg",
	},
	{
		original: "gallery/works (4).jpg",
		thumbnail: "gallery/works (4).jpg",
	},
	{
		original: "gallery/works (5).jpg",
		thumbnail: "gallery/works (5).jpg",
	},
	{
		original: "gallery/works (6).jpg",
		thumbnail: "gallery/works (6).jpg",
	},
	{
		original: "gallery/works (7).jpg",
		thumbnail: "gallery/works (7).jpg",
	},
	{
		original: "gallery/works (8).jpg",
		thumbnail: "gallery/works (8).jpg",
	},
	{
		original: "gallery/works (9).jpg",
		thumbnail: "gallery/works (9).jpg",
	},
	{
		original: "gallery/works (10).jpg",
		thumbnail: "gallery/works (10).jpg",
	},
	{
		original: "gallery/works (11).jpg",
		thumbnail: "gallery/works (11).jpg",
	},
	{
		original: "gallery/works (12).jpg",
		thumbnail: "gallery/works (12).jpg",
	},
	{
		original: "gallery/works (13).jpg",
		thumbnail: "gallery/works (13).jpg",
	},
	{
		original: "gallery/works (14).jpg",
		thumbnail: "gallery/works (14).jpg",
	},
	{
		original: "gallery/works (15).jpg",
		thumbnail: "gallery/works (15).jpg",
	},
	{
		original: "gallery/works (16).jpg",
		thumbnail: "gallery/works (16).jpg",
	},
	{
		original: "gallery/works (17).jpg",
		thumbnail: "gallery/works (17).jpg",
	},
	{
		original: "gallery/works (18).jpg",
		thumbnail: "gallery/works (18).jpg",
	},
	{
		original: "gallery/works (19).jpg",
		thumbnail: "gallery/works (19).jpg",
	},
	{
		original: "gallery/works (1).png",
		thumbnail: "gallery/works (1).png",
	},
];

export default function PGallery() {
	const fade = {
		opacity: 1,
		transition: {
			duration: 1.4,
		},
	};

	return (
		<>
			<div className='works' id='works'>
				<div className='container'>
					<motion.div
						initial={{ opacity: 0 }}
						whileInView={fade}
						viewport={{ once: true }}
						className='heading'>
						<p className='heading-sub-text'>Our Works</p>
						<p className='heading-text'>Gallery</p>
					</motion.div>

					<motion.div
						className='works-box'
						initial={{ opacity: 0 }}
						whileInView={fade}>
						<div className="gallery">
							<ImageGallery lazyLoad={true} autoPlay={true} items={images} />
						</div>
					</motion.div>
				</div>
			</div>
		</>
	);
}
