import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
export const Footer = () => {
  return (
    <footer className="footer">
    <div className="waves">
      <div className="wave" id="wave1"></div>
      <div className="wave" id="wave2"></div>
      <div className="wave" id="wave3"></div>
      <div className="wave" id="wave4"></div>
    </div>
    {/* <ul className="social-icon">
      <li className="social-icon__item"><Link className="social-icon__link" to="#">
          <ion-icon name="logo-facebook"></ion-icon>
        </Link></li>
      <li className="social-icon__item"><Link className="social-icon__link" to="#">
          <ion-icon name="logo-twitter"></ion-icon>
        </Link></li>
      <li className="social-icon__item"><Link className="social-icon__link" to="#">
          <ion-icon name="logo-linkedin"></ion-icon>
        </Link></li>
      <li className="social-icon__item"><Link className="social-icon__link" to="#">
          <ion-icon name="logo-instagram"></ion-icon>
        </Link></li>
    </ul> */}
    <ul className="menu">
      <li className="menu__item"><Link className="menu__link" to="#">Home</Link></li>
      <li className="menu__item"><Link className="menu__link" to="#">About</Link></li>
      <li className="menu__item"><Link className="menu__link" to="#">Services</Link></li>
      <li className="menu__item"><Link className="menu__link" to="#">Team</Link></li>
      <li className="menu__item"><Link className="menu__link" to="#">Gallery</Link></li>
    </ul>
    <p>&copy;2023 Pixel | All Rights Reserved</p>
  </footer>
  );
};
