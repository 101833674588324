import React, { useRef } from "react";
import "../styles/Contact.css";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // what is this? really!?

    emailjs.sendForm('service_w3uurf8', 'template_r4l74cp', form.current, 'P-btL8zzZLNP5Tsri')
      .then((result) => {
        toast("Message Sent Successfully!, We will get back to you shortly.");
      }, (error) => {
        toast("Message not sent, Please try again later.");
      });
  };

  const fade = {
    opacity: 1,
    transition: {
      duration: 1.5
    }
  }

  const verticalLeft = {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5
    }
  }


  return (
    <>
      <section className="contact" id='contact'>
        <div className="container">
          <motion.div className="heading" initial={{ opacity: 0 }} whileInView={fade} viewport={{ once: true }}>
            <p className="heading-sub-text">Hire Us</p>
            <p className='heading-text'>Get in Touch</p>
          </motion.div>
          <div className="contact-box">
            <ToastContainer />
            <motion.div className="form-box" initial={{ opacity: 0, y: '50px' }} whileInView={verticalLeft}>

              <form
                name="contact-form"
                method="POST"
                data-netlify="true"
                action="POST"
                ref={form}
                onSubmit={sendEmail}
              >
                <input type="hidden" name="form-name" value="contact-form" />
                <div className="form-top">
                  <div className="name">
                    <label htmlFor="user_name">Your Name</label>
                    <input
                      type="text"
                      name="user_name"
                      id="user_name"
                      placeholder="Enter your name"
                      required
                    />
                  </div>

                  <div className="email">
                    <label htmlFor="user_email">Your Email</label>
                    <input
                      type="user_email"
                      name="user_email"
                      id="user_email"
                      placeholder="Enter your email address"
                      required
                    />
                  </div>
                </div>

                <div className="form-mid">
                  <div className="message">
                    <label htmlFor="message">Your message</label>
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Reach out as soon as you can"
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="form-btn">
                  <button type="submit" className="hero-contact">
                    Send Message
                  </button>
                </div>
              </form>
            </motion.div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
