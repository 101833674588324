import React from 'react';
import About from './About';
import HeroSection from './HeroSection';
import Services from './Services';
import Team from './Team';
import PGallery from './Gallery';
import { Footer } from './Footer';
import Partners from './Partners';
import Contact from './Contact';

const Main = ({ nav, handleNav, closeNav }) => {
  return (
    <div onClick={closeNav} className='main'>
      <HeroSection nav={nav} handleNav={handleNav} />
      <About />
      <Services />
      <Team />
      <PGallery />
      <Partners />
      <Contact />
      <Footer />
    </div>
  )
};

export default Main;
